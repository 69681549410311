<template>
  <div class="box" v-loading.fullscreen.lock="loading">
    <img :src="banner" alt="" style="margin-top: 20px;" width="100%" @click="toDetail">
    <div class="catalogue flex_row">
      <Catalogue class="m_tb_20"
                 v-for="item of catalogueList"
                 :key="item.id"
                 :catalogue="item"/>
    </div>
    <a href="http://wlaqxc.cdjzs.com/index" target="_bland" style="position:fixed;right: 30px;top: 33%;">
    <img :src="wangluoanquan" width="160px" style="border-radius: 10px;"/>
    </a>
  </div>
</template>

<script>
//import banner from 'assets/image/Mask group.png'
// import banner from '@/assets/image/Group 828.png'
import banner from '@/assets/image/Group 829.png'
import wangluoanquan from '@/assets/image/wangluoanquan.png'
// 市
import municipalImg from 'assets/image/Group 653.png'
import provincialImg from 'assets/image/Group 651.png'
import countryImg from 'assets/image/Group 650.png'
import regionImg from 'assets/image/Group 652.png'
import Catalogue from "../components/index/Catalogue";
import {getIndexCatalogueList, getResourceClassifyTree} from "../../../api/resourceCenter";
import {sessionSet} from "../../../utils/local";
// import {
//     countVisitorsByMenu_api
//   } from '@/api/mine'
export default {
  name: 'ResourceIndex',
  components: {
    Catalogue
  },
  data: () => {
    return {
      banner,
      wangluoanquan,
      catalogueList: [
        {
          title: '国家资源',
          id: 'country',
          imgUrl: countryImg,
          catalogues: [
            {name: '德育', url: 'https://basic.smartedu.cn/sedu'},
            {name: '课程教学', url: 'https://basic.smartedu.cn/syncClassroom'},
            {
              name: '体育',
              url: 'https://basic.smartedu.cn/sport?channelId=40bca6ef-ee76-40c8-b059-89e5baf01134&breadcrumb=%E4%BD%93%E8%82%B2%E4%B8%8E%E5%81%A5%E5%BA%B7%E8%AF%BE%E7%A8%8B&libraryId=3cdefab6-f36b-4bf7-ae44-033577ba93af&resourceChannel=library'
            },
            {
              name: '美育',
              url: 'https://basic.smartedu.cn/art?channelId=31741f87-5444-42f9-ac3f-795d7dc7ef7a&breadcrumb=%E8%89%BA%E6%9C%AF%E8%AF%BE%E7%A8%8B&libraryId=7612f2f2-98f1-46e1-b889-36bdecdbe63e&resourceChannel=library'
            },
            {name: '劳动教育', url: 'https://basic.smartedu.cn/labourEdu'},
            {name: '课后服务', url: 'https://basic.smartedu.cn/schoolService'},
            {name: '教师研修', url: 'https://basic.smartedu.cn/teacherTrainingNav'},
            {name: '教改经验', url: 'https://basic.smartedu.cn/eduReform'},
            {name: '教材', url: 'https://basic.smartedu.cn/tchMaterial'}
          ]
        },
        {
          title: '省级资源',
          id: 'provincial',
          imgUrl: provincialImg,
          catalogues: []
        },
        {
          title: '市级资源',
          id: 'municipal',
          imgUrl: municipalImg,
          catalogues: []
        },
        {
          title: '区级资源',
          id: 'region',
          imgUrl: regionImg,
          catalogues: []
        }
      ],
      loading: false
    }
  },
  async mounted() {
    this.loading = true
    sessionSet('searchParams', {})

    // 资源目录
    await getResourceClassifyTree({id: '1548926790947336193'}).then(res => {
      let data = res.data[0].children

      data.forEach((item) => {
        item.name = item.label
        delete item.children
      })
      this.catalogueList[1].catalogues = data
    })
    await getIndexCatalogueList().then(res => {
      res.data.forEach(item => {
        switch (item.level) {
          case '3':
            this.catalogueList[2].catalogues.push(item)
            break
          case '4':
      if(item.remarks1!=1)
            this.catalogueList[3].catalogues.push(item)
            break
        }
      })
    })
    this.loading = false
  },
  methods:{
    toDetail(){
      // countVisitorsByMenu_api({type:5});
      // this.$router.push({path:'/onlineTeaching/index'})
    }
  }
}
</script>

<style scoped>

.box {
  width: 1200px;
  margin: auto;
}

.catalogue {
  justify-content: space-between;
}
</style>