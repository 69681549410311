<template>
  <div class="catalogue-box bg_fff br_10">
    <img :src="catalogue.imgUrl" alt="" width="100%">
    <div class="catalogues p_in_20 flex_col">
      <div class="title fs_18 fw_bold c_333">{{ catalogue.title }}</div>
      <div class="catalogue-list fs_16 flex_row_wrap ">
        <div class="catalogue pointer text_hidden"
             style="position:relative;"
             v-for="(item, index) of catalogue.catalogues"
             :key="item.id"
             v-show="index<12"
             @click="catalogue_click(catalogue.id,item)">
          <div class="text_hidden" style="width:64px;"> {{ item.name }}</div>
          <!--          <div v-show="(index+1)%3 !== 0"-->
          <!--               style="width: 2px;height:10px;background-color: #12C294;position:absolute;top: 6px;right: 14px;"></div>-->
        </div>
      </div>
      <div class="button br_5 fs_16 pointer" @click="viewAll_click(catalogue.id)">查看全部</div>
    </div>
  </div>
</template>

<script>
import img from 'assets/image/Group 650.png'

export default {
  name: 'IndexCatalogue',
  props: {
    catalogue: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => {
    return {img}
  },
  methods: {
    catalogue_click(target, content) {
      switch (target) {
        case 'country':
          window.open(content.url, '_blank');
          break
        case 'provincial':
          this.$router.push({name: 'provincialResource', params: {id: content.id}})
          break
        case 'municipal':
          this.$router.push({name: 'municipalResource', params: {id: content.id}})
          break
        case 'region':
          this.$router.push({name: 'regionResource', params: {id: content.id}})
          break
      }

    },
    viewAll_click(target) {
      switch (target) {
        case 'country':
          window.open('https://www.zxx.edu.cn/', '_blank');
          break
        case 'provincial':
          this.$router.push({name: 'provincialResource'})
          break
        case 'municipal':
          this.$router.push({name: 'municipalResource'})
          break
        case 'region':
          this.$router.push({name: 'regionResource'})
          break
      }
    }
  }
}
</script>

<style scoped>
.catalogue-box {
  width: 290px;
}

.title {
  text-align: center;
  margin-bottom: 15px;
}

.catalogues {
  justify-content: space-between;
  height: 227px;
}

.catalogue-list {
  /*text-align: justify;*/
  /*text-align-last: justify;*/
  text-align: center;
}

.catalogue {
  width: 93px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.6);
  padding-right: 19px;
}

.catalogue:hover {
  color: #12C294;
}

.catalogue:nth-child(3n) {
  padding-right: 0;
  width: 63px;
}

.button {
  width: 140px;
  height: 40px;
  color: #12C294;
  border: 1px solid #12C294;
  text-align: center;
  line-height: 40px;
  margin: 0 auto;
}
</style>